var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { alpha } from '@mui/material/styles';
// ----------------------------------------------------------------------
var COLORS = ['primary', 'secondary', 'info', 'success', 'warning', 'error'];
export default function ToggleButton(theme) {
    var rootStyle = function (ownerState) {
        var standardColor = ownerState.color === 'standard';
        var defaultStyle = __assign({}, (standardColor && {
            '&.Mui-selected': {
                borderColor: 'inherit',
            },
        }));
        var colorStyle = COLORS.map(function (color) { return (__assign({}, (ownerState.color === color && {
            '&:hover': {
                borderColor: alpha(theme.palette[color].main, 0.48),
                backgroundColor: alpha(theme.palette[color].main, theme.palette.action.hoverOpacity),
            },
            '&.Mui-selected': {
                borderColor: theme.palette[color].main,
            },
        }))); });
        var disabledState = {
            '&.Mui-disabled': {
                '&.Mui-selected': {
                    color: theme.palette.action.disabled,
                    backgroundColor: theme.palette.action.selected,
                    borderColor: theme.palette.action.disabledBackground,
                },
            },
        };
        return __spreadArray(__spreadArray([], colorStyle, true), [defaultStyle, disabledState], false);
    };
    return {
        MuiToggleButton: {
            styleOverrides: {
                root: function (_a) {
                    var ownerState = _a.ownerState;
                    return rootStyle(ownerState);
                },
            },
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: theme.shape.borderRadius,
                    backgroundColor: theme.palette.background.paper,
                    border: "solid 1px ".concat(alpha(theme.palette.grey[500], 0.16)),
                },
                grouped: {
                    margin: 4,
                    borderColor: 'transparent !important',
                    borderRadius: "".concat(theme.shape.borderRadius, "px !important"),
                },
            },
        },
    };
}
